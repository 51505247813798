<template>
    <div class="bg-gray-100 text-gray-900">
        <!-- Hero Section -->
        <section class="relative py-16 px-6 text-center    bg-cover bg-center md:bg-center pt-32 pb-32"
            :style="{ backgroundImage: `url(${require('@/assets/img/consultorio/bannerConsultorio2.jpg')})` }">

            <h2 class="neon-text text-5xl font-extrabold text-green-500 animate__animated animate__zoomInDown ">
                <span class="text-green-800 text-6xl">Pierde de 4 a 8 kg</span> en 1 mes de manera segura
            </h2>
            <p class="mt-12 text-2xl font-semibold text-gray-800">Plan alimenticio personalizado + medicamento
                homeopático + seguimiento.<br>
                Reserva tu consulta hoy y empieza tu transformación.</p>

            <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=Hola%2C%20quiero%20agendar%20una%20consulta%20en%20Vida%20Nueva%20y%20Nutrici%C3%B3n.%20%C2%BFCu%C3%A1les%20son%20los%20horarios%20disponibles%3F%20y%20%C2%BFA%C3%BAn%20puedo%20aprovechar%20la%20promoci%C3%B3n%20del%2025%C2%B0%20aniversario%3F%20%0A%C2%A1Gracias!%F0%9F%98%80`"
                target="_blank" @click="$emit('set-click')"
                class="mt-12 inline-block px-9 py-3 text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(0,255,0,0.7)]">
                Haz clic aquí para agendar tu cita
            </a>
        </section>
        <div class="bg-gradient-to-r from-green-400 via-green-600 to-green-400 pt-1 pb-1">
        </div>

        <section class="mt-10 text-center ">
            <h2 class="text-4xl font-extrabold text-gray-800">Vida nueva y nutrición</h2>
            <p class="text-lg text-gray-600 mt-4 mb-8"> ¡Logra perder de 4 a 8 kilos en un mes! El doctor lleva 25 años
                ayudando
                a personas a llegar a su peso ideal, todo por medio de su experiencia y tratamiento homeopático.</p>

        </section>

        <!-- Como Funciona -->
        <section class="mt-16 bg-white p-8 shadow-lg text-center">
            <h3 class="text-3xl font-bold text-gray-800">¿Cómo funciona nuestro tratamiento?</h3>
            <p class="text-lg text-gray-600 mt-4">Sin dietas extremas. Sin pasar hambre. Resultados desde la primera
                semana.</p>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6 mb-8">
                <div
                    class="border-l-4 border-green-500 p-6 bg-green-200 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">

                    <h4 class="text-xl font-bold text-gray-700">Plan Personalizado</h4>
                    <p class="text-gray-600 mt-2">El doctor te otorga tu plan alimenticio 100% personalizado para
                        maximizar tus resultados.</p>
                    <img src="@/assets/img/consultorio/nutricion.png" alt="Planes niutricionales"
                        class="w-45 h-26 mx-auto">
                </div>
                <div
                    class="border-l-4 border-green-500 p-6 bg-green-200 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                    <h4 class="text-xl font-bold text-gray-700">Medicamento Homeopático</h4>
                    <p class="text-gray-600 mt-2">Soluciones naturales para una pérdida de peso efectiva.</p>
                    <img src="@/assets/img/consultorio/homeopatico.png" alt="Medicamento Homeopatico"
                        class="w-45 h-26 mx-auto">
                </div>
                <div
                    class="border-l-4 border-green-500 p-6 bg-green-200 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                    <h4 class="text-xl font-bold text-gray-700">Seguimiento Médico</h4>
                    <p class="text-gray-600 mt-2">Consultas regulares para asegurar tu bienestar.</p>
                    <img src="@/assets/img/consultorio/seguimiento.png" alt="Seguimiento Medicor"
                        class="w-30 h-26 mx-auto">
                </div>
            </div>
            <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=Hola%2C%20quiero%20agendar%20una%20consulta%20en%20Vida%20Nueva%20y%20Nutrici%C3%B3n.%20%C2%BFCu%C3%A1les%20son%20los%20horarios%20disponibles%3F%20y%20%C2%BFA%C3%BAn%20puedo%20aprovechar%20la%20promoci%C3%B3n%20del%2025%C2%B0%20aniversario%3F%20%0A%C2%A1Gracias!%F0%9F%98%80`"
                target="_blank" @click="$emit('set-click')"
                class="px-6 py-3 bg-green-500 text-white font-semibold text-lg rounded-lg hover:bg-green-700">
                📅 Agenda tu consulta hoy
            </a>
        </section>

        <div class="mt-12 mb-12">
            <h2 class="text-3xl font-extrabold text-center animated-gradient">
                "Tú puedes lograrlo. Nosotros te ayudamos a hacerlo realidad"
            </h2>
        </div>

        <!-- por que elegirnos -->
        <section class="py-16 bg-gray-100">
            <div class="max-w-4xl mx-auto text-center">
                <h2 class="text-4xl font-bold text-green-600 mb-6">¿Por qué elegirnos?</h2>
                <p class="text-lg text-gray-700 mb-12">Sabemos que hay muchas opciones para bajar de peso, pero aquí te damos razones para elegirnos y lograr los resultados que buscas de forma segura y efectiva:</p>
            </div>

            <div class="max-w-5xl mx-auto grid md:grid-cols-3 gap-8">
                <!-- Tarjeta 1 -->
                <div class="bg-white border border-gray-300 p-6 rounded-lg shadow-lg text-center hover:shadow-xl transition-shadow">
                    <div class="flex justify-center">
                        <div
                            class="w-16 h-16 bg-green-500 text-white rounded-full flex justify-center items-center text-2xl">
                            👨‍⚕️
                        </div>
                    </div>
                    <h3 class="text-xl font-semibold text-gray-700 mt-4">Acompañamiento médico especializado</h3>
                    <p class="text-gray-600 mt-2">
                        Te guiamos en todo el proceso con consultas de seguimiento para asegurarnos de que avances de
                        manera segura y efectiva.
                    </p>
                </div>

                <!-- Tarjeta 2 -->
                <div class="bg-white border border-gray-300 p-6 rounded-lg shadow-lg text-center hover:shadow-xl transition-shadow">
                    <div class="flex justify-center">
                        <div
                            class="w-16 h-16 bg-blue-500 text-white rounded-full flex justify-center items-center text-2xl">
                            ✨
                        </div>
                    </div>
                    <h3 class="text-xl font-semibold text-gray-700 mt-4">Resultados desde la primera semana</h3>
                    <p class="text-gray-600 mt-2">
                        Notarás cambios en tu peso, medidas y bienestar en los primeros días sin dietas extremas ni
                        sacrificios.
                    </p>
                </div>

                <!-- Tarjeta 3 -->
                <div class="bg-white border border-gray-300 p-6 rounded-lg shadow-lg text-center hover:shadow-xl transition-shadow">
                    <div class="flex justify-center">
                        <div
                            class="w-16 h-16 bg-red-500 text-white rounded-full flex justify-center items-center text-2xl">
                            📍
                        </div>
                    </div>
                    <h3 class="text-xl font-semibold text-gray-700 mt-4">Plan adaptado a tu estilo de vida</h3>
                    <p class="text-gray-600 mt-2">
                        Sabemos que cada persona es diferente. Por eso, diseñamos un plan fácil de seguir, sin afectar
                        tu rutina diaria.
                    </p>
                </div>
            </div>
        </section>


        <section class="gradient-fondo mt-16 text-white p-8 rounded-lg shadow-lg text-center">
            <h3 class="text-3xl font-bold">🎉 ¡25 años transformando vidas! 🎉</h3>
            <p class="text-lg mt-4">Celebra con nosotros este gran aniversario y aprovecha por tiempo limitado nuestra
                promoción exclusiva del <b>50% de descuento</b> en tu primera consulta para alcanzar tu peso ideal.
            </p>
            <!-- Botón -->
            <div class="mt-4 flex justify-center">
                <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=Hola%2C%20quiero%20agendar%20una%20consulta%20en%20Vida%20Nueva%20y%20Nutrici%C3%B3n.%20%C2%BFCu%C3%A1les%20son%20los%20horarios%20disponibles%3F%20y%20%C2%BFA%C3%BAn%20puedo%20aprovechar%20la%20promoci%C3%B3n%20del%2025%C2%B0%20aniversario%3F%20%0A%C2%A1Gracias!%F0%9F%98%80`"
                    target="_blank" @click="$emit('set-click')"
                    class="px-6 py-3 bg-yellow-400 text-white font-semibold text-lg rounded-lg hover:bg-yellow-500">
                    ¡Quiero mi promoción!
                </a>
            </div>
        </section>

        <!-- testimonios -->
        <section class="py-16 px-4 bg-white text-center">
            <h2 class="text-3xl font-bold text-green-600">Lo que dicen nuestros pacientes</h2>
            <div class="mt-6 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto">
                <div class="mt-6 max-w-xl mx-auto bg-gray-200 p-6 rounded-lg shadow flex flex-col">
                    <p class="italic text-lg text-gray-800">
                        "Siempre me costó bajar de peso, pero con ayuda del doctor logre mejorar mi alimentación y
                        llegar a mi peso ideal.
                        ahora tengo mas energia y lo mejor de todo es que el proceso fue sencillo."
                    </p>
                    <p class="mt-2 font-semibold text-right text-green-700">— Paola Muños</p>
                </div>
                <div class="mt-6 max-w-xl mx-auto bg-gray-200 p-6 rounded-lg shadow flex flex-col">
                    <p class="italic text-lg text-gray-800">"Había probado varias dietas sin éxito, pero cuado consulté
                        con el doctor todo combio.
                        Me hizo una valoracion completa, me recetó un tratamiento personalizado y ahora me siento mucho
                        mejor conmigo misma."
                    </p>
                    <p class="mt-2 font-semibold text-right text-green-700">— Susana Gusman</p>
                </div>
            </div>
        </section>

        <!-- beneficios -->
        <div class="bg-white w-full bg-cover bg-center pt-8 pb-12">
            <h2 class=" text-4xl font-extrabold text-center text-green-500 mb-12">Beneficios</h2>
            <div class="flex flex-col md:flex-row justify-center gap-10 px-4">
                <div class="w-full md:w-1/3 bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-cover bg-center"
                    :style="{ backgroundImage: `url(${require('@/assets/img/consultorio/cardFondo.png')})` }">
                    <div class="flex justify-center mb-8">
                        <div class="w-16 h-16 bg-green-500 text-white rounded-full flex justify-center items-center">
                            <span class="text-xl font-bold">1</span>
                        </div>
                    </div>
                    <h3 class="neon-black text-xl font-semibold text-center text-white mb-4">Resultados desde la
                        primera
                        semana
                    </h3>
                    <p class="neon-black text-white text-center mt-2">No tienes que esperar meses para ver cambios.
                        Desde los primeros días notarás que te sientes más ligero, menos inflamado y con más energía.
                    </p>
                </div>

                <div class="w-full md:w-1/3 bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-cover bg-center"
                    :style="{ backgroundImage: `url(${require('@/assets/img/consultorio/cardFondo.png')})` }">
                    <div class="flex justify-center mb-8">
                        <div class="w-16 h-16 bg-green-500 text-white rounded-full flex justify-center items-center">
                            <span class="text-xl font-bold">2</span>
                        </div>
                    </div>
                    <h3 class="neon-black text-xl font-semibold text-center text-white mb-4">Pérdida de peso rápida
                        y
                        segura</h3>
                    <p class="neon-black text-white text-center mt-2">Nuestro trataniento está diseñado para que bajes
                        de 4 a 8 kg al mes sin poner en riesgo tu salud. Nada de dietas extremas ni sacrificios
                        imposibles..</p>
                </div>
            </div>

            <div class="flex flex-col md:flex-row justify-center gap-10 px-4 mt-11">
                <div class="w-full md:w-1/3 bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-cover bg-center"
                    :style="{ backgroundImage: `url(${require('@/assets/img/consultorio/cardFondo.png')})` }">
                    <div class="flex justify-center mb-8">
                        <div class="w-16 h-16 bg-green-500 text-white rounded-full flex justify-center items-center">
                            <span class="text-xl font-bold">3</span>
                        </div>
                    </div>
                    <h3 class="neon-black text-xl font-semibold text-center text-white mb-4">Mayor energia y
                        bienestar
                    </h3>
                    <p class="neon-black text-white text-center mt-2">Al alimentarte mejor y darle a tu cuerpo lo que
                        necesita, notarás que tienes más energía, duermes
                        mejor y te sientes con más ánimo todos los días..</p>
                </div>

                <div class="w-full md:w-1/3 bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-cover bg-center"
                    :style="{ backgroundImage: `url(${require('@/assets/img/consultorio/cardFondo.png')})` }">
                    <div class="flex justify-center mb-8">
                        <div class="w-16 h-16 bg-green-500 text-white rounded-full flex justify-center items-center">
                            <span class="text-xl font-bold">4</span>
                        </div>
                    </div>
                    <h3 class="neon-black text-xl font-semibold text-center text-white mb-4">Método 100% natural y
                        efectivo</h3>
                    <p class="neon-black text-white text-center mt-2">Usamos un enfoque natural con medicina homeopática
                        que ayuda a tu cuerpo a perder peso sin afectar tu salud.
                        Es una forma segura y libre de químicos agresivos.</p>
                </div>
            </div>

            <div class="mt-8 flex justify-center">
                <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=Hola%2C%20quiero%20agendar%20una%20consulta%20en%20Vida%20Nueva%20y%20Nutrici%C3%B3n.%20%C2%BFCu%C3%A1les%20son%20los%20horarios%20disponibles%3F%20y%20%C2%BFA%C3%BAn%20puedo%20aprovechar%20la%20promoci%C3%B3n%20del%2025%C2%B0%20aniversario%3F%20%0A%C2%A1Gracias!%F0%9F%98%80`"
                    target="_blank" @click="$emit('set-click')"
                    class="px-6 py-3 bg-green-500 text-white font-semibold text-lg rounded-lg hover:bg-green-700">
                    📅 Solicita tu primera consulta
                </a>
            </div>
        </div>

        <div class="bg-gradient-to-r from-green-400 via-green-600 to-green-400 pt-1 pb-1">
        </div>

        <!-- FAQ -->
        <section class="py-16 px-4 bg-gray-100 text-center">
            <h2 class="text-3xl font-bold text-green-600">Preguntas Frequentes</h2>
            <details class="mt-4 bg-white p-4 rounded-lg shadow">
                <summary class="cursor-pointer font-semibold">¿Qué es el medicamento homeopático y de qué está hecho?
                </summary>
                <p class="mt-2">Es un tratamiento natural elaborado a partir de extractos de plantas, minerales y otras
                    sustancias
                    naturales en dosis muy pequeñas. Su objetivo es estimular el metabolismo, reducir la ansiedad por la
                    comida y ayudar
                    a tu cuerpo a perder peso de forma equilibrada y segura, sin efectos secundarios.</p>
            </details>
            <details class="mt-4 bg-white p-4 rounded-lg shadow">
                <summary class="cursor-pointer font-semibold">¿El tratamiento es seguro?</summary>
                <p class="mt-2">Sí, nuestro método es 100% natural, basado en un plan alimenticio equilibrado y
                    medicamento homeopático que no afecta tu salud</p>
            </details>
            <details class="mt-4 bg-white p-4 rounded-lg shadow">
                <summary class="cursor-pointer font-semibold">¿En cuánto tiempo veré resultados?</summary>
                <p class="mt-2">Desde la primera semana notarás cambios en tu peso y medidas, además de sentirte con más
                    energía y menos inflamación.</p>
            </details>
            <details class="mt-4 bg-white p-4 rounded-lg shadow">
                <summary class="cursor-pointer font-semibold">¿Necesito hacer ejercicio para bajar de peso?</summary>
                <p class="mt-2">No es obligatorio, pero la actividad física ayuda a acelerar los resultados y mejorar tu
                    bienestar general.</p>
            </details>
        </section>


        <!-- Encuentranos -->
        <div class="bg-gray-100 py-12 px-4">
            <div class="container mx-auto text-center">
                <h2 class="text-3xl font-semibold text-green-600 mb-4">📍 Encuéntranos</h2>
                <p class="text-gray-600 text-lg mb-6">Visítanos en nuestro consultorio y comienza tu transformación.</p>

                <div class="bg-white shadow-lg rounded-lg p-6 sm:p-8 inline-block w-full max-w-lg">
                    <div class="flex items-center justify-center mb-4">
                        <div class="bg-green-500 text-white w-12 h-12 flex justify-center items-center rounded-full">
                            📍
                        </div>
                    </div>
                    <p class="text-xl font-medium text-gray-700">
                        Calle Hidalgo #77, Col. Centro, San Juan del Rio, Querétaro
                    </p>
                    <p class="text-gray-500 mt-2">Lunes a Viernes: 9 AM - 7 PM | Sábados: 9 AM - 2 PM</p>

                    <div class="mt-8 flex justify-center">
                        <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=Hola%2C%20quiero%20agendar%20una%20consulta%20en%20Vida%20Nueva%20y%20Nutrici%C3%B3n.%20%C2%BFCu%C3%A1les%20son%20los%20horarios%20disponibles%3F%20y%20%C2%BFA%C3%BAn%20puedo%20aprovechar%20la%20promoci%C3%B3n%20del%2025%C2%B0%20aniversario%3F%20%0A%C2%A1Gracias!%F0%9F%98%80`"
                            target="_blank" @click="$emit('set-click')"
                            class="px-6 py-2 bg-green-500 text-white font-semibold text-lg rounded-lg hover:bg-green-700">
                            📅 Agendar mi consulta
                        </a>
                    </div>
                    <!-- Mapa de Google embebido -->
                    <div class="mt-4 flex justify-center w-full">
                        <div class="w-full max-w-md">
                            <iframe class="w-full max-w-full h-64 sm:h-80"
                                style="border:0; border-radius: 8px; box-shadow: 0px 4px 10px rgba(0,0,0,0.1);"
                                loading="lazy" allowfullscreen referrerpolicy="no-referrer-when-downgrade"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.567891234567!2d-99.9964444!3d20.3915!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjDCsDIzJzI5LjQiTiA5OcKwNTknNDcuMiJX!5e0!3m2!1ses!2smx!4v1699999999999">
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'consultorio',
    props: {
        participante: {
            type: Object,
            default() {
                return {
                    nombre: "[VSC]",
                    telefono: "4271615141",
                    dato_identificacion: "00000000",
                    web_url: "https://virtualshoppingclub.com",
                    registro_url: "https://virtualshoppingclub.com/registro/1904121"
                }
            }
        },
        sinParticipantes: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            methods: [
                { name: "Consulta medica", safe: true, noBounce: true, fastResults: true, experience: "25 Años" },
                { name: "Dietas comunes", safe: false, noBounce: false, fastResults: false, experience: "" },
                { name: "Pastillas milagro", safe: false, noBounce: false, fastResults: false, experience: "" },
            ],
        };
    },
    methods: {
        getIcon(value) {
            return value
                ? '<span class="text-green-600 text-lg">✅</span>'
                : '<span class="text-red-600 text-lg">❌</span>';
        },
        formatExperience(value) {
            return value ? value : "N/A"; // Si no tiene experiencia, muestra "N/A"
        },
    },
}

</script>

<style scoped>
body {
    font-family: Arial, sans-serif;
}

.neon-text {
    text-shadow: 0 0 90px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff;
}

.neon-black {
    text-shadow: 0 0 20px #000000, 0 0 10px #000000, 0 0 20px #000000;
}

/* Animación para resaltar la palabra activa */
.neon-hover {
    animation: neonAnimation 1.5s infinite alternate;
}

/* Efecto Parallax */
.parallax {
    background-image: url('@/assets/img/consultorio/bannerFondo.jpg');
    height: 500px;
    background-attachment: fixed;
    /* Aquí ocurre la magia */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@keyframes gradientChange {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.gradient-bg {
    background: linear-gradient(45deg, #16f734, #086ce7, #10ef4b, #2575fc);
    background-size: 400% 400%;
    animation: gradientChange 4s ease infinite;
}

.gradient-fondo {
    background: linear-gradient(45deg, #16f734, #086ce7, #10ef4b, #2575fc);
    background-size: 400% 400%;
    animation: gradientChange 4s ease infinite;
}

.animated-gradient {
    animation: pulse 2s infinite;
    font-size: 2rem;
    /* Ajusta el tamaño */
    font-weight: 800;
    /* Fuente extrabold */
    text-align: center;
    /* Centrar texto */
    background: linear-gradient(90deg, #35c54f, #08d70c, #35c54f);
    /* Gradiente de 3 colores */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    /* El gradiente aparecerá en lugar del color sólido */
    animation: gradient-animation 3s infinite;
    /* Añade la animación */
}

@keyframes gradient-animation {

    0%,
    100% {
        background-position: 0% 50%;
        transform: scale(0.8);
    }

    50% {
        background-position: 100% 50%;
        transform: scale(0.9);
    }
}
</style>