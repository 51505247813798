<template>
    <div class="bg-gradient-to-b from-black to-gray-700 min-h-screen text-white bg-center"
        :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoImg.jpg')})` }">

        <section
            class="relative w-full h-screen flex flex-col justify-center items-center text-center text-white overflow-hidden">

            <!-- Video de fondo -->
            <video autoplay muted loop playsinline class="absolute top-0 left-0 w-full h-full object-cover">
                <source src="@/assets/videos/videoFondo.mp4" type="video/mp4">
                Tu navegador no soporta videos en HTML5.
            </video>

            <!-- Capa oscura para mejorar la visibilidad del texto -->
            <div class="absolute top-0 left-0 w-full h-full bg-black opacity-10"></div>

            <!-- Contenido encima del video -->
            <div class="relative z-10 px-6">
                <h1 class="neon-black text-2xl font-bold mb-4 animate__animated animate__zoomInDown animate__slow">
                    ¡Prepárate para el lanzamiento más esperado del otoño 2025!
                </h1>
                <h2 class="neon-text text-5xl font-extrabold text-indigo-500 animate__animated animate__zoomInDown">
                    Legends of Interactions
                </h2>

                <div
                    class="neon-black flex flex-col md:flex-row justify-center space-y-4 md:space-x-8 mt-12 text-2xl font-bold animate__animated animate__zoomInDown animate__slower">
                    <span v-for="(item, index) in items" :key="index" :class="{
                        'text-white transition duration-300': true,
                        'neon-hover': activeIndex === index
                    }">
                        {{ item }}
                    </span>
                </div>


                <h2 class="neon-black text-2xl font-semibold mt-12 mb-4 px-2">
                    Sumérgete en un universo donde la fantasía y la realidad chocan en una experiencia única.
                </h2>
            </div>
        </section>



        <div class="bg-gradient-to-r from-black via-gray-700 to-black pt-1 pb-1">
        </div>

        <div class="py-12 px-6 max-w-5xl mx-auto text-center mb-6">
            <h1 class="text-3xl font-bold text-yellow-400">
                Un Mundo sin Límites
            </h1>
            <p class=" text-2xl mt-2"><b>Legends of Interactions</b>, el revolucionario videojuego de ciencia
                ficción que te sumerge en un mundo donde la fantasía y la realidad se encuentran en el umbral de
                la singularidad digital. Aquí, no solo juegas, <b>tienes la oportunidad de poseer tu propio territorio
                    virtual y generar ingresos a través de interacciones únicas.</b>
            </p>
            <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%22%C2%A1Hola!%20Estoy%20interesado%20en%20conocer%20m%C3%A1s%20sobre%20Legends%20of%20Interactions%20y%20c%C3%B3mo%20puedo%20participar.%20%C2%BFMe%20puedes%20dar%20m%C3%A1s%20informaci%C3%B3n%3F%22%F0%9F%A4%96%F0%9F%8E%AE`" target="_blank" @click="$emit('set-click')"
                class="mt-8 mb-6 inline-block px-9 py-3 text-yellow-400 font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.7)]">
                💬 Comunicate con un asesor ahora
            </a>

        </div>

        <!-- Que te espera -->
        <section class="py-12 px-6 max-w-5xl mx-auto text-center">
            <h3 class="text-3xl font-bold text-yellow-400 mb-8"> Qué te espera en <span
                    class="text-white font-extrabold">Legends Of Interactions</span></h3>
            <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-8">
                <div
                    class="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                    <h4 class="text-xl font-bold mb-4 text-yellow-400">🎮 Realidad Aumentada sin Límites</h4>
                    <p class="text-gray-800 font-bold text-justify">➡️Camina, explora y descubre un mundo digital superpuesto a la
                        realidad. <br><br>
                        ➡️ Las calles, parques y edificios cobran vida con misiones y desafíos interactivos.</p>
                </div>
                <div
                    class="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                    <h4 class="text-xl font-bold mb-4 text-yellow-400">💰 Gana mientras los jugadores
                        exploran</h4>
                    <p class="text-gray-800 font-bold text-justify">➡️ Si eres Maestro del Juego, cada jugador que entra en tu
                        parcela digital genera beneficios para ti.<br><br>
                        ➡️ Construye experiencias únicas y aumenta tus ingresos en el juego.</p>
                </div>
                <div
                    class="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                    <h4 class="text-xl font-bold mb-4 text-yellow-400">🌍 Parcelas Digitales en Todo el Mundo
                    </h4>
                    <p class="text-gray-800 font-bold text-justify">➡️ Compra y administra terrenos digitales en ubicaciones
                        estratégicas.<br><br>
                        ➡️ Los jugadores podrán interactuar con tus espacios y desbloquear recompensas.</p>
                </div>
            </div>
        </section>


        <div class="px-6 max-w-5xl mx-auto text-center mb-12">
            <img src="@/assets/img/loi-master/opportunity.jpg" alt="Icono loi"
                class="h-78 mx-auto transform hover:scale-110 transition">
            <p class="text-2xl font-bold text-withe">
                Sumérgete en un mundo donde las posibilidades son infinitas.
                Interactúa con otros jugadores y objetos
                virtuales mientras
                exploras una dimensión única llena de secretos y desafíos.
            </p>
            <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%22%C2%A1Hola!%20Estoy%20interesado%20en%20conocer%20m%C3%A1s%20sobre%20Legends%20of%20Interactions%20y%20c%C3%B3mo%20puedo%20participar.%20%C2%BFMe%20puedes%20dar%20m%C3%A1s%20informaci%C3%B3n%3F%22%F0%9F%A4%96%F0%9F%8E%AE`" target="_blank" @click="$emit('set-click')"
                class="mt-8 mb-6 inline-block px-9 py-3 text-yellow-400 font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.7)]">
                ¡Más Información!
            </a>
        </div>

        <div class="bg-gradient-to-r from-black via-gray-700 to-black pt-1 pb-1">
        </div>

        <!-- elijan su experiencia -->
        <div class=" flex flex-col items-center text-center justify-center min-h-screen text-white px-6 bg-cover bg-center"
            :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondo3.jpg')})` }">
            <img src="@/assets/img/loi-master/logo.jpg" alt="Icono loi" class="h-28 mx-auto">
            <h2 class="neon-text text-3xl font-bold text-yellow-400 mb-6 relative">
                ELIJAN SU ROL DE EXPERIENCIA
                <span class="absolute -top-2 left-1/2 -translate-x-1/2 w-2 h-2 bg-pink-500 rounded-full"></span>
            </h2>
            <div class="flex flex-col md:flex-row gap-6 w-full max-w-4xl">
                <!-- Creadores de mercado -->
                <div
                    class="flex-1 bg-[#1C2541] p-6 rounded-lg shadow-lg border border-gray-600 transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                    <h3 class="text-2xl font-bold">MARKET MAKERS</h3>
                    <h4 class="text-yellow-400 font-bold mb-4">Negocio basado en un juego</h4>
                    <p class="text-gray-300">
                        Creen orbes y objetos, pónganlos a la venta en el Marketplace, gestionen el negocio en la
                        franquicia del juego y obtengan
                        <span class="font-bold">remuneraciones.</span>
                    </p>
                    <p class="text-green-400 font-bold mt-4 mb-4">✅ ¡Abierto ahora! Participa hoy.</p>
                    <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%C2%A1Hola!%20Me%20interesa%20ser%20un%20Market%20Maker%20en%20Legends%20of%20Interactions%F0%9F%8E%AE%20y%20crear%20objetos%20para%20el%20Marketplace.%20%C2%BFC%C3%B3mo%20puedo%20comenzar%3F%F0%9F%92%A5`"
                        target="_blank" @click="$emit('set-click')"
                        class="mt-4 mb-6 bg-yellow-400 text-gray-900 font-bold py-2 px-6 rounded-full text-sm shadow-md hover:shadow-lg hover:bg-yellow-500 transition-all">
                        Quiero <span class="text-blue-800">Unirme</span>
                    </a>
                </div>

                <!-- Masters Card -->
                <div
                    class="flex-1 bg-[#1C2541] p-6 rounded-lg shadow-lg border border-gray-600 transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                    <h3 class="text-2xl font-bold">MASTERS OF GAME</h3>
                    <h4 class="text-yellow-400 font-bold mb-4">Negocio basado en un juego</h4>
                    <p class="text-gray-300">
                        Desarrollen el juego, llénenlo de objetos, gestionen el negocio en la franquicia del juego y
                        obtengan
                        <span class="font-bold">remuneraciones.</span>
                    </p>
                    <p class="text-green-400 font-bold mt-4 mb-4">✅ ¡Abierto ahora! Participa hoy.</p>
                    <!-- Botón -->
                    <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%C2%A1Hola!%20Me%20interesa%20la%20oportunidad%20de%20invertir%20en%20parcelas%20digitales%F0%9F%93%88%20y%20generar%20ingresos%20en%20el%20juego.%20%F0%9F%8E%AE%C2%BFPodr%C3%ADas%20darme%20m%C3%A1s%20detalles%20sobre%20c%C3%B3mo%20funciona%3F%E2%9C%A8`"
                        target="_blank" @click="$emit('set-click')"
                        class="mt-4 mb-6 bg-yellow-400 text-gray-900 font-bold py-2 px-6 rounded-full text-sm shadow-md hover:shadow-lg hover:bg-yellow-500 transition-all">
                        Quiero <span class="text-blue-800">Unirme</span>
                    </a>
                </div>

                <!-- Player Card -->
                <div class="flex-1 bg-[#1C2541] p-6 mb-2 rounded-lg shadow-lg border border-gray-600 opacity-80">
                    <h3 class="text-2xl font-bold">PLAYER</h3>
                    <h4 class="text-yellow-400 font-bold mb-4">Juego gratuito con compras dentro del juego</h4>
                    <p class="text-gray-300">
                        Jueguen y disfruten del proceso. Completen misiones, interactúen en la comunidad y obtengan
                        <span class="font-bold">remuneraciones dentro del juego.</span>
                    </p>
                    <p class="text-red-400 font-bold mt-4 mb-4">🚧 Próximamente: Apertura en otoño 2025.</p>
                    <a
                        class="mt-4 mb-6 bg-yellow-400 text-gray-900 font-bold py-2 px-6 rounded-full text-sm shadow-md hover:shadow-lg hover:bg-yellow-500 transition-all">
                        Quiero <span class="text-blue-800">Unirme</span>
                    </a>
                </div>
            </div>
        </div>

        <div class="bg-gradient-to-r from-black via-gray-700 to-black pt-1 pb-1">
        </div>

        <!-- Sección de Testimonios -->
        <div class="py-16 px-6 mt-12">
            <h3 class="text-center text-4xl font-bold text-white">📢 Lo Que Dice La Comunidad de LOI</h3>
            <div class="testimonials">
                <Carousel :items-to-show="1" :wrap-around="true" :autoplay="4000">
                    <Slide v-for="(testimonial, index) in testimonials" :key="index">
                        <div class="testimonial-card max-w-xl mx-auto">
                            <p class="message italic ">"{{ testimonial.message }}"</p>
                            <h3 class="author text-right">- {{ testimonial.author }}</h3>
                        </div>
                    </Slide>
                    <template #addons>
                        <Navigation />
                        <Pagination />
                    </template>
                </Carousel>
            </div>
        </div>



        <!-- master of game-->
        <section class="flex justify-center items-center px-4 mt-12 mb-16">
            <div class="p-8 rounded-lg border border-white shadow-lg w-full max-w-full md:max-w-5xl text-center bg-cover bg-center"
                :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoAzul.jpg')})` }">
                <h3 class="text-3xl text-white font-bold mb-6">
                    Sé más que un jugador: <span class="text-4xl text-yellow-400">¡Conviértete en Master Of Game!</span>
                </h3>
                <ul class="space-y-3 text-white text-left neon-black">
                    <li class="flex items-center">
                        <span class="text-lg mr-2">✅</span> Compra parcelas virtuales únicas y obtén beneficios reales.
                    </li>
                    <li class="flex items-center">
                        <span class="text-lg mr-2">✅</span> Gana por las interaccione de los jugadores dentro de tu
                        territorio.
                    </li>
                    <li class="flex items-center">
                        <span class="text-lg mr-2">✅</span> Economía descentralizada integrada al blockchain NETSBO.
                    </li>
                </ul>
                <p class="mt-6 neon-black">
                    Las parcelas no son solo espacios dentro del juego, son activos que generan ingresos. Conecta con
                    jugadores,
                    organiza eventos, crea mercados y haz que tu territorio cobre vida.
                </p>
            </div>
        </section>

        <section
            class="w-full max-w-full md:max-w-5xl px2 mx-auto py-12 px-6 bg-black text-white rounded-lg border border-yellow-500 relative shadow-lg mb-16">
            <div class="absolute top-0 left-0 transform -translate-x-2 -translate-y-2 w-3 h-3 bg-pink-500 rounded-full">
            </div>
            <div class="flex flex-col md:flex-row items-center gap-6">
                <div class="md:w-3/5">
                    <h3 class="text-3xl font-bold text-yellow-400 mb-4">POSEAN</h3>
                    <p class="text-lg">
                        <strong>¡Legends of Interactions</strong> une el mundo virtual y el real! Háganse propietarios
                        de prestigiosos
                        terrenos en los lugares más atractivos del planeta, desde Singapur hasta México. Aprovechen
                        estas oportunidades
                        no solo para poseer, sino también para ganar dinero en un universo de juego en rápido
                        desarrollo. ¡Aquí su éxito
                        no tiene límites!
                    </p>
                </div>
                <div class="md:w-2/5">
                    <img src="@/assets/img/loi-master/own.jpg" alt="Mapa del juego"
                        class="rounded-lg shadow-lg border border-yellow-400">
                </div>
            </div>
        </section>

        <!-- creador de mercado-->
        <section class="flex justify-center items-center px-4 mt-16 mb-16">
            <div class="p-8 rounded-lg border border-white shadow-lg w-full max-w-full md:max-w-5xl text-center bg-cover bg-center"
                :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoAzul.jpg')})` }">
                <h3 class="text-3xl text-white font-bold mb-6">
                    Infinitas posibilidades de ganancias al ser: <span class="text-4xl text-yellow-400">¡Market
                        Makers!</span>
                </h3>
                <ul class="space-y-3 text-white text-left neon-black">
                    <li class="flex items-center">
                        <span class="text-lg mr-2">✅</span> Crea Orber y objetos y obtén beneficios reales.
                    </li>
                    <li class="flex items-center">
                        <span class="text-lg mr-2">✅</span> Gana por la venta de los orbes y objetos en el marketplace
                        Antallagi.io.
                    </li>
                    <li class="flex items-center">
                        <span class="text-lg mr-2">✅</span> Economía descentralizada integrada al blockchain NETSBO.
                    </li>
                    <li class="flex items-center">
                        <span class="text-lg mr-2">✅</span> Multiples formas de generar ganancias con los programas 7Pin
                        Token
                        y con el programa H - Map.
                    </li>
                </ul>
                <p class="mt-6 neon-black">
                    Infinitas formas de farmear y comercializar en el juego. crea objetos únicos, vende en el
                    marketplace y gana.
                </p>
            </div>
        </section>

        <div class="bg-gradient-to-r from-black via-gray-700 to-black pt-1 pb-1">
        </div>

        <!-- Llamada a la acción -->
        <div class="bg-linear-to-r from-orange-500 to-yellow-500 py-16 relative z-20 bg-cover bg-center"
        :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/bannerAzul.jpg')})` }">
            <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                <!-- Sección de Preguntas Frecuentes -->
            <div class="mb-6 px-4 text-center text-gray-800">
                <h3 class="neon-text text-5xl font-bold text-yellow-500"> Preguntas Frecuentes</h3>
                <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
                    <summary class="font-semibold text-lg cursor-pointer">¿Necesito experiencia previa en videojuegos
                        para
                        participar? </summary>
                    <p class="mt-2">
                        No es obligatorio, pero tener conocimientos sobre mecánicas de juego y experiencia en liderazgo
                        ayudará
                        a desempeñar mejor tu rol.
                    </p>
                </details>
                <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
                    <summary class="font-semibold text-lg cursor-pointer"> ¿Puedo empezar antes del lanzamiento?
                    </summary>
                    <p class="mt-2">
                        Sí, puedes prepararte como Creador de Mercado o Maestro del Juego y aprender sobre el sistema
                        económico
                        del juego y diseñar
                        estrategias para aprovecharlas en el lanzamiento.
                    </p>
                </details>

                <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
                    <summary class="font-semibold text-lg cursor-pointer"> ¿Cuándo se lanza Legends of Interactions?
                    </summary>
                    <p class="mt-2"> 
                        El videojuego se lanzará en otoño del presente año, pero para los que sean Maestros del Juego podrán acceder a una versión Beta en Abril del 2025
                    </p>
                </details>
            </div>

                <h2 class="text-4xl text-white font-serif font-extrabold text-white mb-6 mt-12">
                    🎮 ¡Elige tu estrategia y domina Legends of Interactions!
                </h2>
                <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%22Estoy%20listo%20para%20explorar%20un%20mundo%20sin%20l%C3%ADmites.%20%F0%9F%8C%8D%20Cu%C3%A9ntame%20m%C3%A1s%20sobre%20las%20oportunidades%20en%20LOI.%22`" target="_blank" @click="$emit('set-click')"
                    class="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-lg text-lg">
                    ¡Quiero Unirme!
                </a>
            </div>
        </div>

    </div>
</template>
<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default defineComponent({
    name: "loi-master",
    components: { Carousel, Slide, Pagination, Navigation },
    props: {
        participante: {
            type: Object,
            default() {
                return {
                    nombre: "[VSC]",
                    telefono: "4271615141",
                    dato_identificacion: "00000000",
                    web_url: "https://virtualshoppingclub.com",
                    registro_url: "https://virtualshoppingclub.com/registro/1904121",
                };
            },
        },
        sinParticipantes: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeIndex: 0,
            items: ["Explora", "Conquista", "Posee", "Gana"],
            interval: null,
            testimonials: [
                { message: "He visto la evolución de los videojuegos, pero Legends of Interactions es algo totalmente innovador. No solo planeo liderar este mundo digital, sino también jugar y disfrutar cada aspecto de esta experiencia única. Este juego redefine lo que significa ser un gamer y un inversor al mismo tiempo.", author: "Bryan C. Master Of Game" },
                { message: "Siempre me han fascinado los videojuegos y los negocios, y en Legends of Interactions encontré la combinación ideal. Como Creador de Mercado, podré diseñar economías, aprovechar la demanda de ítems digitales y generar ingresos desde el primer día. Me estoy preparando para tener ventaja desde el inicio.", author: "Briseida P. Market Makers" },
                { message: "Ser Maestro del Juego en Legends of Interactions significa más que solo jugar; es tener el control de una experiencia única para otros jugadores. Desde ahora, me estoy preparando para aprender las mecánicas, estrategias y herramientas que me permitirán guiar a miles de jugadores en este nuevo universo digital. ¡Cuando el juego se lance, estaré listo para liderar!", author: "Berenice S. Master Of gamee" },
            ],
        };
    },
    mounted() {
        this.startAnimation();
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
    methods: {
        startAnimation() {
            this.interval = setInterval(() => {
                this.activeIndex = (this.activeIndex + 1) % this.items.length;
            }, 1500); // Cambia cada 1.5 segundos
        },
    },
});
</script>

<style scoped>
.neon-text {
    text-shadow: 0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff;
}

.neon-black {
    text-shadow: 0 0 10px #000000, 0 0 20px #000000, 0 0 30px #000000;
}

/* Animación para resaltar la palabra activa */
.neon-hover {
    animation: neonAnimation 1.5s infinite alternate;
}


.testimonials {
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 13px;
}

.testimonial-card {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.message {
    font-size: 1.2rem;
    color: #303030;
}

.author {
    font-size: 1rem;
    font-weight: bold;
    color: #000dff;
    margin-top: 10px;
}

/* Efecto de brillo y escala */
@keyframes neonAnimation {
    0% {
        text-shadow: 0 0 5px #002aff, 0 0 10px #002aff, 0 0 15px #002aff, 0 0 20px #002aff;
        transform: scale(1.2);
    }

    100% {
        text-shadow: 0 0 10px #000000, 0 0 20px #000000, 0 0 30px #000000, 0 0 40px #000000;
        transform: scale(1);
    }
}

/* Efecto de colores en el boton */
@keyframes gradientChange {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.gradient-bg {
    background: linear-gradient(45deg, #0008ff, #000a30, #0008ff, #000b35);
    background-size: 400% 400%;
    animation: gradientChange 4s ease infinite;
}
</style>