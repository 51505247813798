<template>
  <div class="font-sans bg-white min-h-screen flex flex-col items-center">
    <!-- Header Section -->
    <header
      class="w-full bg-gradient-to-t from-green-500 to-cyan-700 pt-12 pb-20 text-center shadow-lg relative overflow-hidden">
      <h1 class=" text-6xl text-white text-center font-extrabold animate__animated animate__jackInTheBox">
        Vive la vida <span class="text-yellow-400">con descuentos</span></h1>

      <p class="mt-6 px-2 text-xl text-gray-100 text-center mx-auto animate__animated animate__jackInTheBox">
        Aprovecha nuestros cupones para obtener descuentos exclusivos en tus compras, bonificación en puntos canjeables
        y
        la oportunidad de generar ganancias extra. ¡Y lo mejor! Puedes duplicar tus cupones por tus compras y obtener el
        doble de recompensas!
      </p>
      <img src="@/assets/img/cupones1.png" alt="cupones" class="w-82 mx-auto mt-9 animate-bounce">



      <div class="p-6 max-w-2xl mx-auto bg-gray-100 rounded-2xl shadow-md space-y-2" v-if="$route.name === 'cupones'">
        <h2 class="text-2xl font-bold text-center text-gray-800 mb-4">Selecciona tu ubicación para ver los negocios
          participantes</h2>
        <div class="flex flex-wrap gap-4 justify-center">

          <!-- Select País -->
          <div class="w-full sm:w-auto">
            <label class="block mb-1 text-gray-700">País</label>
            <select v-model="selectedCountry" @change="fetchStates"
              class="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="" disabled>Seleccione un país</option>
              <option v-for="pais in paises" :key="pais.id" :value="pais">
                {{ pais.nombre }}
              </option>
            </select>
          </div>

          <!-- Select Estado -->
          <div v-if="estados.length" class="w-full sm:w-auto">
            <label class="block mb-1 text-gray-700">Estado</label>
            <select v-model="selectedState" @change="fetchMunicipios"
              class="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="" disabled>Seleccione un estado</option>
              <option v-for="estado in estados" :key="estado.id" :value="estado">
                {{ estado.nombre }}
              </option>
            </select>
          </div>

          <!-- Select Municipio -->
          <div v-if="municipios.length" class="w-full sm:w-auto">
            <label class="block mb-1 text-gray-700">Municipio</label>
            <select v-model="selectedMunicipio" @change="fetchNegocios()"
              class="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option value="" disabled>Seleccione un municipio</option>
              <option v-for="municipio in municipios" :key="municipio.id" :value="municipio">
                {{ municipio.nombre }}
              </option>
            </select>
          </div>

        </div>
      </div>

    </header>

    <!--Cómo Funciona Section  -->
    <div class="bg-white w-full bg-cover bg-center pt-12 pb-12">
      <h2 class=" text-4xl font-bold text-center text-green-500 mb-12">✨ ¡Así de fácil es disfrutar tus descuentos! ✨
      </h2>
      <div class="flex flex-col md:flex-row justify-center gap-10 px-4">
        <div
          class="w-full md:w-1/4 bg-gradient-to-t from-green-500 to-cyan-700 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-cover bg-center">
          <div class="flex justify-center mb-8">
            <div class="w-16 h-16 bg-white text-white rounded-full flex justify-center items-center">
              <span class="text-green-500 text-2xl font-bold">1</span>
            </div>
          </div>
          <h3 class="neon-black text-xl font-semibold text-center text-white mb-4">Elige tus 2 negocios favoritos de la
            lista de participantes.

          </h3>
        </div>

        <div
          class="w-full md:w-1/4 bg-gradient-to-t from-green-500 to-cyan-700 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-cover bg-center">
          <div class="flex justify-center mb-8">
            <div class="w-16 h-16 bg-white text-white rounded-full flex justify-center items-center">
              <span class="text-green-500 text-2xl font-bold">2</span>
            </div>
          </div>
          <h3 class="neon-black text-xl font-semibold text-center text-white mb-4">Regístrate GRATIS y desbloquea tus
            beneficios.

          </h3>
        </div>
        <div
          class="w-full md:w-1/4 bg-gradient-to-t from-green-500 to-cyan-700 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-cover bg-center">
          <div class="flex justify-center mb-8">
            <div class="w-16 h-16 bg-white text-white rounded-full flex justify-center items-center">
              <span class="text-green-500 text-2xl font-bold">3</span>
            </div>
          </div>
          <h3 class="neon-black text-xl font-semibold text-center text-white mb-4">Recibe tus cupones de descuento
            exclusivos para tus negocios seleccionados.

          </h3>
        </div>

        <div
          class="w-full md:w-1/4 bg-gradient-to-t from-green-500 to-cyan-700 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow bg-cover bg-center">
          <div class="flex justify-center mb-8">
            <div class="w-16 h-16 bg-white text-white rounded-full flex justify-center items-center">
              <span class="text-green-500 text-2xl font-bold">4</span>
            </div>
          </div>
          <h3 class="neon-black text-xl font-semibold text-center text-white mb-4">¡Listo! Usa tus cupones y ahorra en
            grande.

          </h3>
        </div>
      </div>
    </div>


    <!-- negocios participantes -->
    <h2 id="business-section" class="text-4xl mt-16 mb-4 px-2 font-bold text-green-500 text-center">Negocios
      participantes para aplicar tus cupones de descuento</h2>
    <p class="text-lg" v-if="nombreRegion !== null">
      <span class="text-green-500">Región: </span><span class="font-bold text-green-500">{{ nombreRegion }}</span>
    </p>

    <!-- Mostrar negocios disponibles -->
    <section v-if="negocios.length"
      class="bg-gray-200 pt-4 pb-4 border border-gray-400 rounded-lg max-w-6xl grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-8 px-6 overflow-auto max-h-96 mt-4">
      <div v-for="negocio in negocios" :key="negocio.id" @click="toggleNegocioSeleccionado(negocio)" :class="[
        'flex flex-col items-center p-2 rounded-lg cursor-pointer transition-all',
        isSeleccionado(negocio) ? 'bg-green-400' : 'bg-white hover:bg-gray-100'
      ]">
        <img :src="negocio.logo_url" alt="Logo del negocio"
          class="w-full max-w-[120px] max-h-[120px] object-contain mb-2 rounded-lg" />
        <span class="text-center font-bold">{{ negocio.nombre }}</span>
        <span class="text-sm text-black font-semibold" v-if="negocio.ubicaciones">
          📍 {{ negocio.ubicaciones }}
        </span>
        <span class="mt-1 text-xs font-bold px-2 py-1 rounded-full" v-if="negocio.alto_impacto"
          style="background-color: #ff5c00; color: white;">
          ⭐ Alto impacto
        </span>
      </div>
    </section>

    <!-- Imagen por defecto si no hay negocios -->
    <div v-if="mostrarNoNegocios" class="text-center mt-4">
      <img src="@/assets/img/proximamente.png" alt="Sin negocios" class="w-64 h-64 mx-auto mt-2">
    </div>

    <!-- Sección de Cupones Seleccionados -->
    <section v-if="negociosSeleccionados.length"
      class="mt-6 bg-gray-100 p-6 rounded-2xl shadow-lg max-w-5xl mx-auto border border-gray-300">
      <h3 class="text-3xl font-extrabold mb-6 text-center text-green-600">🎉 Tus cupones seleccionados</h3>
      <div class="flex flex-wrap justify-center gap-6">
        <div v-for="negocio in negociosSeleccionados" :key="negocio.id" class="w-[280px] sm:w-[300px] bg-white p-4 rounded-2xl shadow-md border-2 border-dashed border-green-400 
                flex flex-col items-center gap-3 transition-transform hover:scale-105">

          <img :src="negocio.logo_url" alt="Logo del negocio"
            class="w-full max-w-[140px] max-h-[140px] object-contain rounded-lg shadow bg-white" />
          <span class="text-gray-600 text-center">Cupón de descuento para: </span>
          <span class="text-lg font-bold text-center text-gray-800">{{ negocio.nombre }}</span>
        </div>
      </div>

      <!-- Transición del botón -->
      <transition name="fade-slide">
        <router-link v-if="negociosSeleccionados.length === 2" :to="registroRoute" class="block text-center mt-10 px-8 py-4 bg-green-500 text-white text-lg font-bold rounded-xl
                        shadow-xl hover:bg-green-600 transition-all duration-300">
          ¡Obtener cupones!
        </router-link>
      </transition>
    </section>


    <!-- Sólo mostrar cuando la liga tenga una región asociada -->
    <div class="p-6 max-w-2xl mx-auto bg-gray-100 rounded-2xl shadow-md space-y-2 mt-12"
      v-if="$route.name === 'cupones-con-region'">
      <h2 class="text-2xl font-bold text-center text-gray-800 mb-4">
        Selecciona otra ubicación para ver los negocios participantes
      </h2>
      <div class="flex flex-wrap gap-4 justify-center">

        <!-- Select País -->
        <div class="w-full sm:w-auto">
          <label class="block mb-1 text-gray-700">País</label>
          <select v-model="selectedCountry" @change="fetchStates"
            class="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option value="" disabled>Seleccione un país</option>
            <option v-for="pais in paises" :key="pais.id" :value="pais">
              {{ pais.nombre }}
            </option>
          </select>
        </div>

        <!-- Select Estado -->
        <div v-if="estados.length" class="w-full sm:w-auto">
          <label class="block mb-1 text-gray-700">Estado</label>
          <select v-model="selectedState" @change="fetchMunicipios"
            class="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option value="" disabled>Seleccione un estado</option>
            <option v-for="estado in estados" :key="estado.id" :value="estado">
              {{ estado.nombre }}
            </option>
          </select>
        </div>

        <!-- Select Municipio -->
        <div v-if="municipios.length" class="w-full sm:w-auto">
          <label class="block mb-1 text-gray-700">Municipio</label>
          <select v-model="selectedMunicipio" @change="fetchNegocios()"
            class="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option value="" disabled>Seleccione un municipio</option>
            <option v-for="municipio in municipios" :key="municipio.id" :value="municipio">
              {{ municipio.nombre }}
            </option>
          </select>
        </div>

      </div>
    </div>

    <section class="mt-12 flex flex-col items-center justify-center bg-gray-200 p-8 rounded-lg shadow-lg space-y-4">
      <h2 class="text-2xl font-bold text-gray-800">¡Regístrate ahora gratis!</h2>
      <p class="text-lg text-gray-600">Recibirás tus cupones de descuento <span
          class="text-red-500 font-semibold">próximamente</span>.</p>

      <section v-if="inicioCupones" class="mt-10 text-center">
        <div class="text-2xl font-mono bg-white p-4 rounded shadow inline-block">
          {{ countdown.dias }}d :
          {{ countdown.horas.toString().padStart(2, '0') }}h :
          {{ countdown.minutos.toString().padStart(2, '0') }}m :
          {{ countdown.segundos.toString().padStart(2, '0') }}s
        </div>
      </section>
      <!-- Get Coupons Button -->
      <section class="text-center">
        <router-link :to="registroRoute" class="text-center mt-8 inline-block px-6 py-3 bg-green-500 text-white font-bold rounded-lg
                          shadow-lg custom-shadow hover:bg-green-600 hover:shadow-xl transition-all
                          duration-300">
          ¡Registrate ahora!
        </router-link>
      </section>
    </section>

    <div class="bg-gradient-to-b from-green-500 to-cyan-700 pt-8 mt-12 text-white text-center w-full shadow-md">
      <h3 class="text-4xl font-bold mb-4">¡Compartir es ganar! </h3>
      <p class="px-2"><br>
        Tus invitados te hacen ganar más cupones, por cada amigo que se registre contigo recibes ¡2 cupones
        adicionales!<br>
        Más amigos, más cupones, más ahorros,<br> ♦️ <b>¡Todos ganan!</b> ♦️

      </p>
      <img src="@/assets/img/cupones3.png" alt="cupones" class="w-52 h-52 mx-auto mt-4">
      <p class="text-2xl px-2 mb-6"> <b>¡No te quedes fuera!</b> <br>Por cada cupón aplicado de tus amigos y familiares
        recibes un peso de ganancia.
        🔥👥</p>

      <!-- Botón para ir a negocios participantes -->
      <section class=" bottom-4 right-4 mb-18 ">
        <button @click="scrollToBusinesses"
          class="px-6 py-3 bg-green-500 text-white rounded-lg text-lg font-semibold transition transform hover:scale-105">
          Ver Negocios Participantes
        </button>
      </section>
    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {
      paises: [],
      estados: [],
      municipios: [],
      negocios: [],
      selectedCountry: '',
      selectedState: '',
      selectedMunicipio: '',
      mostrarNoNegocios: false, // Nueva variable para controlar la visibilidad

      noPatrocinador: "",
      nombreRegion: null,
      negociosSeleccionados: [],

      inicioCupones: null,
      countdown: {
        dias: 0,
        horas: 0,
        minutos: 0,
        segundos: 0
      },
      intervaloCountdown: null
    };
  },
  props: {
    patrocinadores: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    // Obtener fecha de inicio de cupones y arrancar el contador
    async fetchInicioCupones() {
      await this.axios.get("/api/v2/cupones").then((response) => {
        if (response.data.inicio_cupones) {
          this.inicioCupones = new Date(response.data.inicio_cupones)
          this.startCountdown()
        } else {
          this.inicioCupones = null
        }
      }).catch(() => {
        this.inicioCupones = null
      })
    },

    startCountdown() {
      if (this.intervaloCountdown) clearInterval(this.intervaloCountdown)

      this.intervaloCountdown = setInterval(() => {
        const ahora = new Date()
        const diferencia = this.inicioCupones - ahora

        if (diferencia <= 0) {
          clearInterval(this.intervaloCountdown)
          this.countdown = { dias: 0, horas: 0, minutos: 0, segundos: 0 }
          return
        }

        const segundosTotales = Math.floor(diferencia / 1000)
        this.countdown.dias = Math.floor(segundosTotales / (60 * 60 * 24))
        this.countdown.horas = Math.floor((segundosTotales / (60 * 60)) % 24)
        this.countdown.minutos = Math.floor((segundosTotales / 60) % 60)
        this.countdown.segundos = segundosTotales % 60
      }, 1000)
    },



    // Alternar la selección de un negocio
    toggleNegocioSeleccionado(negocio) {
      const index = this.negociosSeleccionados.findIndex((n) => n.id === negocio.id)

      if (index !== -1) {
        // Si ya está seleccionado, lo deselecciona
        this.negociosSeleccionados.splice(index, 1)
      } else if (this.negociosSeleccionados.length < 2) {
        // Si no está seleccionado y hay espacio, lo selecciona
        this.negociosSeleccionados.push(negocio)
      } else {
        alert("Solo puedes seleccionar 2 cupones.")
      }
    },

    // Verificar si un negocio está seleccionado
    isSeleccionado(negocio) {
      return this.negociosSeleccionados.some((n) => n.id === negocio.id)
    },

    // Obtener países y estados
    async fetchCountries() {
      await this.axios.get("api/v2/negocios-gratuitos/regiones-disponibles.json").then((response) => {
        if (response.data.mapa) {
          this.paises = response.data.mapa
        } else {
          this.paises = []
        }
      }).catch(() => {
        this.paises = []
      })
    },

    // Obtener estados del país seleccionado
    fetchStates() {
      if (this.selectedCountry) {
        this.estados = this.selectedCountry.estados
        this.municipios = []
        this.resetNegocios()
      }
    },

    // Obtener municipios del estado seleccionado
    fetchMunicipios() {
      if (this.selectedState) {
        this.municipios = this.selectedState.municipios
        this.resetNegocios()
      }
    },

    // Obtener negocios del municipio seleccionado
    async fetchNegocios(id = null) {
      if (this.selectedMunicipio || id) {
        this.negociosSeleccionados = []
        const regionId = id || this.selectedMunicipio.id
        localStorage.setItem('regionIdCupones',regionId)
        await this.axios.get(`api/v2/negocios-gratuitos/by-region/${regionId}.json`).then((response) => {
          this.nombreRegion = response.data.nombre_region
          if (response.data.negocios && response.data.negocios.length > 0) {
            this.negocios = response.data.negocios
            this.mostrarNoNegocios = false // Ocultar mensaje si hay negocios
          } else {
            this.negocios = []
            this.mostrarNoNegocios = true // Mostrar mensaje si no hay negocios
          }
        }).catch(() => {
          this.negocios = []
          this.mostrarNoNegocios = true // Mostrar mensaje en caso de error
        })
      }
    },
    //boton de scroll
    scrollToBusinesses() {
      const businessSection = this.$el.querySelector("#business-section")
      if (businessSection) {
        businessSection.scrollIntoView({ behavior: "smooth" })
      }
    },
    resetNegocios() {
      this.negocios = []
      this.nombreRegion = null
      this.negociosSeleccionados = []
      this.mostrarNoNegocios = false
      localStorage.setItem('regionIdCupones',null)
    }
  },
  async mounted() {
    localStorage.setItem('regionIdCupones',null)
    if (this.$route.params.noPatrocinador) {
      this.noPatrocinador = this.$route.params.noPatrocinador
    }
    if (this.$route.name === 'cupones-con-region') {
      localStorage.setItem('regionIdCupones',this.$route.params.regionId)
      await this.fetchNegocios(Number(this.$route.params.regionId))
    }
    if (this.$route.name === 'campania') {
      this.negocios = this.patrocinadores
    } else {
      await this.fetchCountries()
    }
    await this.fetchInicioCupones()
  },
  computed: {
    registroRoute() {
      if (this.$route.name === 'campania') {
        return {
          name: 'registroCampania',
          params: { codigo: this.$route.params.codigo, patrocinadores: this.negociosSeleccionados.map(n => n.id) }
        }
      } else {
        return {
          name: 'registro',
          params: { noPatrocinador: this.noPatrocinador, patrocinadores: this.negociosSeleccionados.map(n => n.id) }
        }
      }
    },
  },
};



</script>

<style scoped>
/* Animaciones y estilos futuristas */
.neon-text {
  text-shadow: 0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff;
}

.neon-black {
  text-shadow: 0 0 20px #000000, 0 0 10px #000000, 0 0 20px #000000;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.5s ease;
}

.fade-slide-enter-from {
  opacity: 0;
  transform: translateY(20px);
}

.fade-slide-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.fade-slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
