<template>
    <div class="parallax">
        <div class="flex flex-col items-center justify-center text-center px-4">
            <img src="@/assets/img/loi-master/masterofgame.png" alt="publicidad" class="mt-8 w-80 h-20 mx-auto ">
            <h1
                class="neon-text font-serif text-5xl text-gray-800 font-extrabold animate__animated animate__zoomInLeft animate__slow">
                ¡DOMINA EL MERCADO DIGITAL Y GANA DESDE EL PRIMER DÍA!
            </h1>
            <p class="neon-black text-white mt-10 text-lg animate__animated animate__zoomInRight animate__slower">
                La nueva era de los videojuegos no solo trata de jugar. <b>Ahora puedes ser el dueño de la economía del
                    juego.</b>

            </p>
            <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%C2%A1Hola!%20Me%20interesa%20la%20oportunidad%20de%20invertir%20en%20parcelas%20digitales%F0%9F%93%88%20y%20generar%20ingresos%20en%20el%20juego.%20%F0%9F%8E%AE%C2%BFPodr%C3%ADas%20darme%20m%C3%A1s%20detalles%20sobre%20c%C3%B3mo%20funciona%3F%E2%9C%A8`" target="_blank" @click="$emit('set-click')"
                class="mt-8 mb-12 inline-block px-9 py-3 text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.7)]">
                ¡Quiero ser Master Of Games!
            </a>
        </div>
    </div>
    <div class="bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-300 pt-1 pb-1">
    </div>

    <section class="p-6 px-12 flex flex-col items-center gap-6">
        <!-- Imagen -->
        <img src="@/assets/img/loi-master/logo.jpg" alt="Insignia Gestor" class="w-45 h-22">
        <!-- Texto -->
        <div class="text-center">
            <p class="text-[#714E1E] text-justify text-lg">
                Desde hoy, hasta el lanzamiento en otoño de 2025, tienes la oportunidad de <b>ser pionero en el nuevo
                    modelo del gaming.</b>
                En Legends of Interaction, no solo juegas… <b> Eres dueño.</b>
            </p>
        </div>
    </section>

    <!-- En Legens Of Interactions -->
    <section class="py-12 px-6 max-w-5xl mx-auto text-center mt-8">
        <h3 class="text-3xl font-bold text-[#714E1E] mb-8"> En Legends Of Interaction, puedes:</h3>
        <div class="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-8">
            <div
                class="bg-[#90c7ca] p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                <h4 class="text-xl font-bold mb-4 text-[#714E1E] stroke-white stroke-2"> Comprar parcelas digitales</h4>
                <p class="neon-gradiente text-white">y generar ingresos pasivos.</p>
            </div>
            <div
                class="bg-[#90c7ca] p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                <h4 class=" text-xl font-bold mb-4 text-[#714E1E]"> Suministrar recursos a los jugadores</h4>
                <p class="neon-gradiente text-white">y crear un mercado próspero.</p>
            </div>
            <div
                class="bg-[#90c7ca] p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                <h4 class=" text-xl font-bold mb-4 text-[#714E1E]">Aprovechar el blockchain NETSBO</h4>
                <p class="text-white"> para intercambiar activos con valor real.</p>
            </div>
        </div>
    </section>

    <img src="@/assets/img/loi-master/human.png" alt="Icono loi" class="h-58 mx-auto">
    <div class="py-8 px-6 max-w-5xl mx-auto text-center mb-12">
        <p class="text-2xl font-bold text-withe">
            Sumérgete en un mundo donde las posibilidades son infinitas.
            Interactúa con otros jugadores y objetos
            virtuales mientras
            exploras una dimensión única llena de secretos y desafíos.
        </p>
    </div>

    <section
        class="w-full max-w-full md:max-w-5xl px2 mx-auto py-12 px-6 bg-black text-white rounded-lg border border-yellow-500 relative shadow-lg mb-16">
        <div class="absolute top-0 left-0 transform -translate-x-2 -translate-y-2 w-3 h-3 bg-pink-500 rounded-full">
        </div>
        <div class="flex flex-col md:flex-row items-center gap-6">
            <div class="md:w-3/5">
                <h3 class="text-3xl font-bold text-yellow-400 mb-4">POSEAN</h3>
                <p class="text-lg">
                    <strong>¡Legends of Interactions</strong> une el mundo virtual y el real! Háganse propietarios
                    de prestigiosos
                    terrenos en los lugares más atractivos del planeta, desde Singapur hasta México. Aprovechen
                    estas oportunidades
                    no solo para poseer, sino también para ganar dinero en un universo de juego en rápido
                    desarrollo. ¡Aquí su éxito
                    no tiene límites!
                </p>
            </div>
            <div class="md:w-2/5">
                <img src="@/assets/img/loi-master/own.jpg" alt="Mapa del juego"
                    class="rounded-lg shadow-lg border border-yellow-400">
            </div>
        </div>
    </section>

    <!-- elijan su experiencia -->
    <div class="bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-300 pt-1 pb-1">
    </div>
    <div class=" flex flex-col text-center items-center justify-center min-h-screen text-white px-6 bg-cover bg-center"
        :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/bannerAzul.jpg')})` }">
        <img src="@/assets/img/loi-master/logo.jpg" alt="Icono loi" class="h-28 mx-auto">
        <h2 class="neon-black text-3xl font-bold text-yellow-400 mb-6 relative">
            ELIJAN SU ROL DE EXPERIENCIA
            <span class="absolute -top-2 left-1/2 -translate-x-1/2 w-2 h-2 bg-pink-500 rounded-full"></span>
        </h2>
        <div class="flex flex-col md:flex-row gap-6 w-full max-w-4xl">
            <!-- Creadores de mercado -->
            <div
                class="flex-1 bg-[#1C2541] p-6 rounded-lg shadow-lg border border-gray-600 transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                <h3 class="text-2xl font-bold">MARKET MAKERS</h3>
                <h4 class="text-yellow-400 font-bold mb-4">Negocio basado en un juego</h4>
                <p class="text-gray-300">
                    Creen orbes y objetos, pónganlos a la venta en el Marketplace, gestionen el negocio en la
                    franquicia del juego y obtengan
                    <span class="font-bold">remuneraciones.</span>
                </p>
                <p class="text-green-400 font-bold mt-4">✅ ¡Abierto ahora! Participa hoy.</p>
            </div>

            <!-- Masters Card -->
            <div
                class="flex-1 bg-[#1C2541] p-6 rounded-lg shadow-lg border border-gray-600 transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                <h3 class="text-2xl font-bold">MASTERS OF GAME</h3>
                <h4 class="text-yellow-400 font-bold mb-4">Negocio basado en un juego</h4>
                <p class="text-gray-300">
                    Desarrollen el juego, llénenlo de objetos, gestionen el negocio en la franquicia del juego y
                    obtengan
                    <span class="font-bold">remuneraciones.</span>
                </p>
                <p class="text-green-400 font-bold mt-4">✅ ¡Abierto ahora! Participa hoy.</p>
            </div>

            <!-- Player Card -->
            <div class="flex-1 bg-[#1C2541] p-6 rounded-lg shadow-lg border border-gray-600 opacity-80">
                <h3 class="text-2xl font-bold">PLAYER</h3>
                <h4 class="text-yellow-400 font-bold mb-4">Juego gratuito con compras dentro del juego</h4>
                <p class="text-gray-300">
                    Jueguen y disfruten del proceso. Completen misiones, interactúen en la comunidad y obtengan
                    <span class="font-bold">remuneraciones dentro del juego.</span>
                </p>
                <p class="text-red-400 font-bold mt-4">🚧 Próximamente: Apertura en otoño 2025.</p>
            </div>
        </div>

        <!-- Botón -->
        <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%22%C2%A1Hola!%20Estoy%20interesado%20en%20conocer%20m%C3%A1s%20sobre%20Legends%20of%20Interactions%20y%20c%C3%B3mo%20puedo%20participar.%20%C2%BFMe%20puedes%20dar%20m%C3%A1s%20informaci%C3%B3n%3F%22%F0%9F%A4%96%F0%9F%8E%AE`" target="_blank" @click="$emit('set-click')"
                class="mt-8 mb-12 bg-yellow-400 text-gray-900 font-bold py-3 px-8 rounded-full text-lg shadow-lg hover:scale-105 transition-transform">
                Quiero formar parte de <span class="text-blue-800">Legens Of Interactions</span>
            </a>
    </div>
    
    <div class="bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-300 pt-1 pb-1">
    </div>

    <!-- blockchain netsbo-->
    <section
        class="w-full max-w-full md:max-w-5xl px2 mx-auto py-12 px-6 bg-[#90c7ca] text-white rounded-lg border border-yellow-500 relative shadow-lg mt-12 mb-16">
        <div class="absolute top-0 left-0 transform -translate-x-2 -translate-y-2 w-3 h-3 bg-pink-500 rounded-full">
        </div>
        <div class="flex flex-col md:flex-row items-center gap-6">
            <div class="md:w-3/5">
                <h3 class="text-3xl font-bold text-[#714E1E] mb-4">Un Ecosistema Impulsado por Blockchain</h3>
                <p class="text-lg text-[#714E1E]">
                    Legends of Interactions integra el blockchain <b>NETSBO</b>, permitiendo una economía
                    descentralizada y segura. Conecta tus activos con el
                    marketplace <b>Antallagí.io</b> y lleva tu experiencia de juego al siguiente nivel con recursos
                    tokenizados que realmente tienen valor.
                    <b>Ganar dentro del juego nunca había sido tan real.</b>
                </p>
            </div>
            <div class="md:w-2/5">
                <img src="@/assets/img/loi-master/netsbo-logo.jpg" alt="Mapa del juego"
                    class="rounded-lg shadow-lg border border-yellow-400 p-4 transform hover:scale-110 transition">
            </div>
        </div>
    </section>

    <!-- Llamada a la acción -->
    <div class="bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-300 pt-1 pb-1">
    </div>
    <div class="parallax bg-gray-600 py-16 relative z-20">
        <!-- CTA -->
        <section class="max-w-3xl mx-auto text-center py-12 px-6">
            <h2 class="neon-text font-serif text-4xl font-bold text-gray-800"> ¡Sé de los Primeros en un Mundo Nuevo!
            </h2>
            <p class="neon-black mt-4 text-lg text-white">👉 No te quedes fuera. Contáctanos ahora mismo por WhatsApp y
                sé de los primeros en explorar este mundo único.</p>
            <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%22Estoy%20listo%20para%20explorar%20un%20mundo%20sin%20l%C3%ADmites.%20%F0%9F%8C%8D%20Cu%C3%A9ntame%20m%C3%A1s%20sobre%20las%20oportunidades%20en%20LOI.%22`" target="_blank" @click="$emit('set-click')"
                class="mt-18 mb-12 inline-block px-9 py-3 text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.7)]">
                📲 Chatea con un asesor
            </a>

            <p class="neon-text font-serif mt-4 text-lg text-blue-900 font-bold">Legends of Interactions — La Frontera
                Entre
                la Realidad y lo Digital.</p>
        </section>
    </div>
    <div class="bg-gradient-to-r from-yellow-300 via-yellow-500 to-yellow-300 pt-1 pb-1">
    </div>

</template>


<script>
export default {
    name: 'loi-master4',
    props: {
        participante: {
            type: Object,
            default() {
                return {
                    nombre: "[VSC]",
                    telefono: "4271615141",
                    dato_identificacion: "00000000",
                    web_url: "https://virtualshoppingclub.com",
                    registro_url: "https://virtualshoppingclub.com/registro/1904121"
                }
            }
        },
        sinParticipantes: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped>
.neon-text {
    text-shadow: 0 0 0px #ffffff, 0 0 10px #ffffff, 0 0 10px #ffffff;
}

.neon-black {
    text-shadow: 0 0 20px #000000, 0 0 10px #000000, 0 0 20px #000000;
}

.neon-gradiente {
    text-shadow: 0 0 0px #000000, 0 0 20px #000000, 0 0 50px #000000;
}

.neon-borde {
    text-shadow: 0 0 0px #ffffff, 0 0 0px #ffffff, 0 0 0px #ffffff;
}

.parallax {
    background-image: url('@/assets/img/loi-master/bannerPlanet.jpg');
    height: 500px;
    background-attachment: fixed;
    /* Aquí ocurre la magia */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>