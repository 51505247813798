<template>
    <section class="titulo relative py-16 px-6 text-center min-h-screen bg-cover bg-center md:bg-center pt-32 pb-32"
        :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/bannerFondo.png')})` }">
        <h1 class="neon-text text-6xl font-extrabold text-gray-800 animate__animated animate__zoomInDown">
            EL GAMING EVOLUCIONA Y TÚ PUEDES SER DUEÑO DESDE EL INICIO
        </h1>
        <div
            class="neon-black flex flex-col md:flex-row justify-center space-y-4 md:space-x-8 mt-12 text-2xl font-bold animate__animated animate__zoomInDown animate__slower">
            <span v-for="(item, index) in items" :key="index" :class="{
                'text-white transition duration-300': true,
                'neon-hover': activeIndex === index
            }">
                {{ item }}
            </span>
        </div>
        <div>
            <h2 class="neon-black text-2xl font-bold text-white mt-12 mb-4">Legends of Interactions — La Frontera Entre
                la Realidad y lo Digital.</h2>
        </div>
    </section>



    <div class="text-center bg-gradient-to-b from-black via-blue-800 to-black">

        <!-- Sección: Beneficios y Oportunidades -->
        <div class=" py-8">
            <div class="max-w-7xl mx-auto px-6 lg:px-8">
                <section class="p-6 px-8 flex flex-col md:flex-row items-center gap-6">
                    <!-- Imagen -->
                    <img src="@/assets/img/loi-master/mog-logo.jpg" alt="Insignia Gestor" class="w-45 h-9">
                    <!-- Texto -->
                    <div class="text-center md:text-left">
                        <h2 class="font-serif text-2xl font-bold text-white mb-2">🎮 ¿Estás Listo para el Cambio?
                        </h2>
                        <p class="text-white text-justify">
                            Bienvenido a <b>Legends of Interactions</b> el revolucionario videojuego de ciencia ficción
                            que te
                            sumerge en un mundo donde la fantasía y la realidad se encuentran en el umbral de la
                            singularidad digital.
                            Aquí, no solo juegas, <b>tienes la oportunidad de poseer tu propio territorio virtual y
                                generar ingresos</b> a través de interacciones únicas.
                        </p>
                    </div>
                </section>
                <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%22%C2%A1Hola!%20Estoy%20interesado%20en%20conocer%20m%C3%A1s%20sobre%20Legends%20of%20Interactions%20y%20c%C3%B3mo%20puedo%20participar.%20%C2%BFMe%20puedes%20dar%20m%C3%A1s%20informaci%C3%B3n%3F%22%F0%9F%A4%96%F0%9F%8E%AE`" target="_blank" @click="$emit('set-click')"
                    class="mt-4 mb-18 inline-block px-9 py-3 text-yellow-500 font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.7)]">
                    💬 Comunicate con un asesor ahora
                </a>


                <!-- Nuevo texto agregado -->
                <h2 class="font-serif text-3xl font-extrabold text-center text-white mt-6 mb-8">
                    🌐 Un Mundo sin Límites
                </h2>
                <p class="text-white px-6 text-center">
                    En Legends of Interactions, cada jugador define su camino. ¿Serás un explorador en busca de nuevos
                    descubrimientos, un estratega capturando territorios
                    o un comerciante construyendo un imperio económico?<br><br>

                    <span class="font-bold">El juego te ofrece múltiples formas de interacción:</span>
                </p>
                <section class="flex flex-wrap justify-center gap-6 mt-10 mb-10">
                    <!-- Card 1 -->
                    <div class="parrallax bg-black p-6 rounded-lg shadow-lg w-72 text-center text-white  bg-cover bg-center transition-transform transform hover:scale-105  hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]"
                        :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoAzul.jpg')})` }">
                        <h3 class="neon-black text-xl font-bold text-white">⚔️ <b>Intercambio de recursos </b> y
                            exploración de nuevos territorios.</h3>
                    </div>

                    <!-- Card 2 -->
                    <div class="bg-black p-6 rounded-lg shadow-lg w-72 text-center text-white  bg-cover bg-center transition-transform transform hover:scale-105  hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]"
                        :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoAzul.jpg')})` }">
                        <h3 class="neon-black text-xl font-bold text-white">🌾 <b>Farming y desarrollo</b> de
                            infraestructuras digitales.</h3>
                    </div>

                    <!-- Card 3 -->
                    <div class="bg-black p-6 rounded-lg shadow-lg w-72 text-center text-white  bg-cover bg-center transition-transform transform hover:scale-105  hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]"
                        :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoAzul.jpg')})` }">
                        <h3 class="neon-black text-xl font-bold text-white">💬 <b>Captura de territorios</b>
                            y dominio estratégico.
                        </h3>
                    </div>
                    <!-- Card 4 -->
                    <div class="bg-black p-6 rounded-lg shadow-lg w-72 text-center text-white bg-cover bg-center transition-transform transform hover:scale-105  hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]"
                        :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoAzul.jpg')})` }">
                        <h3 class="neon-black text-xl font-bold text-white">💎 <b>IComercio de bienes virtuales</b> y
                            recursos tokenizados.
                        </h3>
                    </div>

                    <!-- Card 5 -->
                    <div class="bg-black p-6 rounded-lg shadow-lg w-72 text-center text-white bg-cover bg-center transition-transform transform hover:scale-105  hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]"
                        :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoAzul.jpg')})` }">
                        <h3 class="neon-black text-xl font-bold text-white">🏆 <b>Conexión y comunicación</b>
                            con otros jugadores en tiempo real.</h3>
                    </div>
                </section>
            </div>
        </div>

        <div class="bg-gradient-to-b from-blue-600 to-gray-600 pt-1 pb-1">
        </div>

        <!-- elijan su experiencia -->
        <div class=" flex flex-col items-center justify-center min-h-screen text-white px-6 bg-cover bg-center"
            :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/banner1.jpg')})` }">
            <img src="@/assets/img/loi-master/logo.jpg" alt="Icono loi" class="h-28 mx-auto">
            <h2 class="neon-black text-3xl font-serif font-bold text-yellow-400 mb-6 relative">
                ELIJAN SU ROL DE EXPERIENCIA
                <span class="absolute -top-2 left-1/2 -translate-x-1/2 w-2 h-2 bg-pink-500 rounded-full"></span>
            </h2>
            <div class="flex flex-col md:flex-row gap-6 w-full max-w-4xl">
                <!-- Creadores de mercado -->
                <div
                    class="flex-1 bg-[#1C2541] p-6 rounded-lg shadow-lg border border-gray-600 transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                    <h3 class="text-2xl font-bold">MARKET MAKERS</h3>
                    <h4 class="text-yellow-400 font-bold mb-4">Negocio basado en un juego</h4>
                    <p class="text-gray-300">
                        Creen orbes y objetos, pónganlos a la venta en el Marketplace, gestionen el negocio en
                        la
                        franquicia del juego y obtengan
                        <span class="font-bold">remuneraciones.</span>
                    </p>
                    <p class="text-green-400 font-bold mt-4">✅ ¡Abierto ahora! Participa hoy.</p>
                </div>

                <!-- Masters Card -->
                <div
                    class="flex-1 bg-[#1C2541] p-6 rounded-lg shadow-lg border border-gray-600 transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
                    <h3 class="text-2xl font-bold">MASTERS OF GAME</h3>
                    <h4 class="text-yellow-400 font-bold mb-4">Negocio basado en un juego</h4>
                    <p class="text-gray-300">
                        Desarrollen el juego, llénenlo de objetos, gestionen el negocio en la franquicia del
                        juego y
                        obtengan
                        <span class="font-bold">remuneraciones.</span>
                    </p>
                    <p class="text-green-400 font-bold mt-4">✅ ¡Abierto ahora! Participa hoy.</p>
                </div>

                <!-- Player Card -->
                <div class="flex-1 bg-[#1C2541] p-6 rounded-lg shadow-lg border border-gray-600 opacity-80">
                    <h3 class="text-2xl font-bold">PLAYER</h3>
                    <h4 class="text-yellow-400 font-bold mb-4">Juego gratuito con compras dentro del juego</h4>
                    <p class="text-gray-300">
                        Jueguen y disfruten del proceso. Completen misiones, interactúen en la comunidad y
                        obtengan
                        <span class="font-bold">remuneraciones dentro del juego.</span>
                    </p>
                    <p class="text-red-400 font-bold mt-4">🚧 Próximamente: Apertura en otoño 2025.</p>
                </div>
            </div>

            <!-- Botón -->
            <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%22%C2%A1Hola!%20Estoy%20interesado%20en%20conocer%20m%C3%A1s%20sobre%20Legends%20of%20Interactions%20y%20c%C3%B3mo%20puedo%20participar.%20%C2%BFMe%20puedes%20dar%20m%C3%A1s%20informaci%C3%B3n%3F%22%F0%9F%A4%96%F0%9F%8E%AE`" target="_blank" @click="$emit('set-click')"
                class="mt-8 mb-12 bg-yellow-400 text-gray-900 font-bold py-3 px-8 rounded-full text-lg shadow-lg hover:scale-105 transition-transform">
                Quiero formar parte de <span class="text-blue-800">Legens Of Interactions</span>
            </a>
        </div>

        <div class="bg-gradient-to-b from-gray-600 to-black pt-1 pb-1">
        </div>

        <!-- MOG y marketplace -->
        <div class="bg-gradient-to-b from-black via-blue-800 to-black py-14 relative z-20 bg-cover bg-center">
            <!-- Economy Section -->
            <section class=" py-12 px-6 bg-cover bg-center">
                <div class="max-w-5xl mx-auto text-center">
                    <h2 class="neon-text font-serif text-4xl font-bold text-gray-800"> No Solo Juegues, ¡Invierte y
                        Gana!</h2>
                    <p class="neon-black mt-4 text-lg text-white">Legends of Interactions ofrece una oportunidad única:
                        ser dueño de
                        parcelas virtuales
                        dentro del juego. Cada parcela genera ingresos pasivos basados en la actividad e interacciones
                        que ocurran en
                        ella. Mientras otros juegan, tú ganas.</p>
                </div>
                <div class="mt-8 flex justify-center gap-6">

                    <img src="@/assets/img/loi-master/mog-logo.jpg" alt="Antallagi Logo"
                        class="w-40 h-10 transform hover:scale-110 transition">
                </div>
            </section>


            <!-- Marketplace -->
            <section class=" py-12 px-6 bg-cover bg-center">
                <div class="max-w-5xl mx-auto text-center">
                    <h2 class="neon-text font-serif text-4xl font-bold text-gray-800"> Su Negocio en el Marketplace</h2>
                    <p class="neon-black mt-4 text-lg text-white">
                        Utiliza <span class="text-yellow-400 font-semibold">Antallagí.io</span> para realizar
                        intercambios de forma
                        segura y construir tu
                        imperio digital.<br><br> <span class="text-yellow-400 font-semibold">Antallagí.io</span> es su
                        herramienta
                        para el comercio de recursos y tierras de juego.
                        Gracias a la red blockchain <span class="text-yellow-400 font-semibold">NETSBO</span> , pueden
                        gestionar con
                        confianza sus activos digitales y llegar a
                        un público enorme, escalando su negocio.</p>
                </div>
                <div class="mt-8 flex justify-center gap-6">

                    <img src="@/assets/img/loi-master/antallagi-logo.png" alt="Antallagi Logo"
                        class="w-40 h-auto transform hover:scale-110 transition">
                    <img src="@/assets/img/loi-master/netsbo-logo.jpg" alt="Antallagi Logo"
                        class="w-40 h-10 transform hover:scale-110 transition">
                </div>
            </section>
        </div>

        <!-- Llamada a la acción -->
  <div class="parallax bg-gray-600 py-16 relative z-20">
    <!-- CTA -->
    <section class="max-w-3xl mx-auto text-center py-12 px-6">
      <h2 class="neon-text font-serif text-4xl font-bold text-yellow-500"> ¡Sé de los Primeros en un Mundo Nuevo!</h2>
      <p class="neon-black mt-4 text-lg text-white">Reserva tu parcela hoy y sé parte de la élite de
        jugadores-inversores.</p>
      <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%22%C2%A1Hola!%20Estoy%20interesado%20en%20conocer%20m%C3%A1s%20sobre%20Legends%20of%20Interactions%20y%20c%C3%B3mo%20puedo%20participar.%20%C2%BFMe%20puedes%20dar%20m%C3%A1s%20informaci%C3%B3n%3F%22%F0%9F%A4%96%F0%9F%8E%AE`" target="_blank" @click="$emit('set-click')"
                    class="mt-18 mb-12 inline-block px-9 py-3 text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.7)]">
                    💬 Comunicate con un asesor ahora
                </a>
    </section>
  </div>


    </div>
</template>

<script>
export default {
    name: 'loi-master3',
    props: {
        participante: {
            type: Object,
            default() {
                return {
                    nombre: "[VSC]",
                    telefono: "4271615141",
                    dato_identificacion: "00000000",
                    web_url: "https://virtualshoppingclub.com",
                    registro_url: "https://virtualshoppingclub.com/registro/1904121"
                }
            }
        },
        sinParticipantes: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeIndex: 0,
            items: ['Explora', 'Conquista', 'Posee', 'Gana'],
            interval: null
        };
    },
    mounted() {
        this.startAnimation();
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
    methods: {
        startAnimation() {
            this.interval = setInterval(() => {
                this.activeIndex = (this.activeIndex + 1) % this.items.length;
            }, 1500); // Cambia cada 1.5 segundos
        }
    }
};
</script>

<style scoped>
.neon-text {
    text-shadow: 0 0 10px #ffffff, 0 0 20px #ffffff, 0 0 30px #ffffff;
}

.neon-black {
    text-shadow: 0 0 20px #000000, 0 0 10px #000000, 0 0 20px #000000;
}

/* Animación para resaltar la palabra activa */
.neon-hover {
    animation: neonAnimation 1.5s infinite alternate;
}

/* Efecto de brillo y escala */
@keyframes neonAnimation {
    0% {
        text-shadow: 0 0 5px #ff00b7, 0 0 10px #ff00b7, 0 0 15px #ff00b7, 0 0 20px #ff00b7;
        transform: scale(1.2);
    }

    100% {
        text-shadow: 0 0 10px #000000, 0 0 20px #000000, 0 0 30px #000000, 0 0 40px #000000;
        transform: scale(1);
    }
}

@media (max-width: 568px) {
    .titulo h1 {
        font-size: 3.3rem;
    }
}


/* Efecto de colores en el boton */
@keyframes gradientChange {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.gradient-bg {
    background: linear-gradient(45deg, #0008ff, #000a30, #0008ff, #000b35);
    background-size: 400% 400%;
    animation: gradientChange 4s ease infinite;
}

.parallax {
  background-image: url('@/assets/img/loi-master/bannerFondo.png');
  height: 500px;
  background-attachment: fixed;
  /* Aquí ocurre la magia */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
