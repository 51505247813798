<template>
  <!-- Sección Parallax Inicial -->
  <div class="parallax">
    <div class="flex flex-col items-center justify-center text-center px-4">
      <img src="@/assets/img/loi-master/logo.jpg" alt="publicidad"
        class="w-70 h-43 mx-auto animate__animated animate__zoomInUp">
      <h1
        class="neon-text font-serif text-5xl text-yellow-500 font-extrabold animate__animated animate__zoomInLeft animate__slow">
        ¡El Futuro del Gaming está Aquí!
      </h1>
      <p class="neon-black text-white mt-10 text-lg animate__animated animate__zoomInRight animate__slower">
        No solo juegues, <b>¡posee, conquista y gana!</b> Sumérgete en una experiencia que difumina los límites entre la
        realidad y el mundo digital en <span class="text-yellow-500ch">Legends Of Interactions</span>.

      </p>
    </div>
  </div>

  <!-- Sección: Beneficios y Oportunidades -->
  <div class="mt-8 py-8">
    <div class="max-w-7xl mx-auto px-6 lg:px-8">
      <section class="border border-black rounded-lg p-6 px-8 flex flex-col items-center mb-14 w-full">
        <!-- Imagen -->
        <img src="@/assets/img/loi-master/logo.jpg" alt="Insignia Gestor" class="w-45 h-26">

        <!-- Texto -->
        <div class="text-center mb-6">
          <h2 class="font-serif text-2xl font-bold text-gray-700 mb-2"></h2>
          <p class="text-gray-700">
            Legends of Interactions es una aventura de ciencia ficción que mezcla el mundo real con el digital,
            creando una experiencia única donde puedes explorar, comerciar y conquistar territorios.
          </p>
        </div>

        <!-- Card 1 -->
        <div
          class="bg-black p-6 rounded-lg shadow-lg w-full max-w-screen-lg text-center text-white bg-cover bg-center transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]"
          :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoHexa.jpg')})` }">
          <h3 class="text-xl font-bold text-white">
            <b>🌍 Explora y Conquista</b>
          </h3>
          <p class="text-white">
            Interactúa con otros jugadores, intercambia recursos, cultiva, comercia y descubre nuevas oportunidades para
            crecer dentro del juego.
          </p>
        </div>

        <!-- Card 2 -->
        <div
          class="p-6 mt-2 mb-2 rounded-lg shadow-lg w-full max-w-screen-lg text-center text-white bg-cover bg-center transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]"
          :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoHexa.jpg')})` }">
          <h3 class="text-xl font-bold text-white">
            <b>💡 Innovación Real</b>
          </h3>
          <p class="text-white">
            Algunos elementos del juego están conectados a la blockchain de NETSBO, permitiéndote intercambiar
            recursos digitales en la plataforma <b>Antallagí.io</b>, haciendo que tu experiencia vaya más allá del
            entretenimiento.
          </p>
        </div>

        <!-- Card 3 -->
        <div
          class="p-6 rounded-lg shadow-lg w-full max-w-screen-lg text-center text-white bg-cover bg-center transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]"
          :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/fondoHexa.jpg')})` }">
          <h3 class="text-xl font-bold text-white">
            <b>👾 Para Todos los Jugadores</b>
          </h3>
          <p class="text-white">
            Tanto si eres principiante como experto, aquí encontrarás tu propio camino al éxito.
          </p>
        </div>
        <p class="text-gray-800 mt-4">
          Prepárate para esta nueva era de los videojuegos y sé de los primeros en experimentar Legends of Interactions.
          <b>¡El futuro del juego empieza ahora! 🚀</b>
        </p>
        <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%22%C2%A1Hola!%20Estoy%20interesado%20en%20conocer%20m%C3%A1s%20sobre%20Legends%20of%20Interactions%20y%20c%C3%B3mo%20puedo%20participar.%20%C2%BFMe%20puedes%20dar%20m%C3%A1s%20informaci%C3%B3n%3F%22%F0%9F%A4%96%F0%9F%8E%AE`" target="_blank" @click="$emit('set-click')"
          class="mt-6 mb-6 inline-block px-9 py-3 text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.7)]">
          💬 Comunicate con un asesor ahora
        </a>
      </section>
    </div>
  </div>

  <!-- elijan su experiencia -->
  <div class=" flex flex-col items-center text-center justify-center min-h-screen text-white px-6 bg-cover bg-center">
    <img src="@/assets/img/loi-master/logo.jpg" alt="Icono loi" class="h-28 mx-auto">
    <h2 class="neon-text text-3xl font-bold text-yellow-400 mb-6 relative">
      ELIJAN SU ROL DE EXPERIENCIA
    </h2>
    <div class="flex flex-col md:flex-row gap-6 w-full max-w-4xl">
      <!-- Creadores de mercado -->
      <div
        class="flex-1 bg-[#1C2541] p-6 rounded-lg shadow-lg border border-gray-600 transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
        <h3 class="text-2xl font-bold">MARKET MAKERS</h3>
        <h4 class="text-yellow-400 font-bold mb-4">Negocio basado en un juego</h4>
        <p class="text-gray-300">
          Creen orbes y objetos, pónganlos a la venta en el Marketplace, gestionen el negocio en la
          franquicia del juego y obtengan
          <span class="font-bold">remuneraciones.</span>
        </p>
        <p class="text-green-400 font-bold mt-4 mb-4">✅ ¡Abierto ahora! Participa hoy.</p>
        <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%C2%A1Hola!%20Me%20interesa%20ser%20un%20Market%20Maker%20en%20Legends%20of%20Interactions%F0%9F%8E%AE%20y%20crear%20objetos%20para%20el%20Marketplace.%20%C2%BFC%C3%B3mo%20puedo%20comenzar%3F%F0%9F%92%A5`"
          target="_blank" @click="$emit('set-click')"
          class="mt-4 mb-6 bg-yellow-400 text-gray-900 font-bold py-2 px-6 rounded-full text-sm shadow-md hover:shadow-lg hover:bg-yellow-500 transition-all">
          Quiero <span class="text-blue-800">Unirme</span>
        </a>
      </div>

      <!-- Masters Card -->
      <div
        class="flex-1 bg-[#1C2541] p-6 rounded-lg shadow-lg border border-gray-600 transition-transform transform hover:scale-105 hover:shadow-[0_4px_15px_rgba(255,255,0,0.7)]">
        <h3 class="text-2xl font-bold">MASTERS OF GAME</h3>
        <h4 class="text-yellow-400 font-bold mb-4">Negocio basado en un juego</h4>
        <p class="text-gray-300">
          Desarrollen el juego, llénenlo de objetos, gestionen el negocio en la franquicia del juego y
          obtengan
          <span class="font-bold">remuneraciones.</span>
        </p>
        <p class="text-green-400 font-bold mt-4 mb-4">✅ ¡Abierto ahora! Participa hoy.</p>
        <!-- Botón -->
        <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=%C2%A1Hola!%20Me%20interesa%20ser%20Master%20Of%20Game%20en%20Legends%20of%20Interactions%20y%20me%20interesa%20la%20oportunidad%20de%20invertir%20en%20parcelas%20digitales%F0%9F%93%88%20y%20generar%20ingresos%20en%20el%20juego.%20%C2%BFPodr%C3%ADas%20darme%20m%C3%A1s%20detalles%20sobre%20c%C3%B3mo%20funciona%3F%E2%9C%A8%F0%9F%8E%AE`"
          target="_blank" @click="$emit('set-click')"
          class="mt-4 mb-6 bg-yellow-400 text-gray-900 font-bold py-2 px-6 rounded-full text-sm shadow-md hover:shadow-lg hover:bg-yellow-500 transition-all">
          Quiero <span class="text-blue-800">Unirme</span>
        </a>
      </div>

    </div>

    <!-- Player Card -->
    <div
      class="flex-none bg-[#1C2541] p-6 mt-6 mb-2 rounded-lg shadow-lg border border-gray-600 bg-cover bg-center min-h-[300px] flex flex-col justify-center items-center"
      :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/proximamente.jpg')})` }">
      <h3 class="text-6xl font-bold">PLAYER</h3>
      <h4 class="neon-text text-yellow-500 font-extrabold mb-4 mt-4">¡Prepárate para la aventura de otoño 2025!</h4>
      <p class="neon-text font-bold text-gray-900 ">
        Mientras esperamos el gran lanzamiento de Legends of Interactions,<br> podrán empezar a conocer y explorar
        todo lo que este mundo tiene para ofrecer.
      </p>
    </div>

  </div>



  <section
    class="mt-6 mb-6 relative w-full h-screen flex flex-col justify-center items-center text-center text-white overflow-hidden">

    <!-- Video de fondo -->
    <video autoplay muted loop playsinline class="absolute top-0 left-0 w-full h-full object-cover">
      <source src="@/assets/videos/videoFondo2.mp4" type="video/mp4">
      Tu navegador no soporta videos en HTML5.
    </video>

    <!-- Capa oscura para mejorar la visibilidad del texto -->
    <div class="absolute top-0 left-0 w-full h-full bg-black opacity-10"></div>

    <!-- Contenido encima del video -->
    <div class="relative z-10 px-2">
        <!-- Sección de Preguntas Frecuentes -->
        <div class="mb-6 px-4 text-center text-gray-800">
                <h3 class="neon-text text-5xl font-bold text-yellow-500"> Preguntas Frecuentes</h3>
                <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
                    <summary class="font-semibold text-lg cursor-pointer">¿Necesito experiencia previa en videojuegos
                        para
                        participar? </summary>
                    <p class="mt-2">
                        No es obligatorio, pero tener conocimientos sobre mecánicas de juego y experiencia en liderazgo
                        ayudará
                        a desempeñar mejor tu rol.
                    </p>
                </details>
                <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
                    <summary class="font-semibold text-lg cursor-pointer"> ¿Puedo empezar antes del lanzamiento?
                    </summary>
                    <p class="mt-2">
                        Sí, puedes prepararte como Creador de Mercado o Maestro del Juego y aprender sobre el sistema
                        económico
                        del juego y diseñar
                        estrategias para aprovecharlas en el lanzamiento.
                    </p>
                </details>

                <details class="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
                    <summary class="font-semibold text-lg cursor-pointer"> ¿Cuándo se lanza Legends of Interactions?
                    </summary>
                    <p class="mt-2"> 
                        El videojuego se lanzará en otoño del presente año, pero para los que sean Maestros del Juego podrán acceder a una versión Beta en Abril del 2025
                    </p>
                </details>
            </div>
    </div>
  </section>

  <!-- Llamada a la acción -->
  <div class="bg-linear-to-r from-orange-500 to-yellow-500 py-16 relative z-20 bg-cover bg-center"
        :style="{ backgroundImage: `url(${require('@/assets/img/loi-master/bannerAzul.jpg')})` }">
    <!-- CTA -->
    <section class="max-w-3xl mx-auto text-center py-12 px-6">
      <h2 class="neon-text font-serif text-4xl font-bold text-yellow-500"> El futuro del gaming está aquí. ¿Serás parte o te quedarás fuera?</h2>
      <p class="neon-black mt-4 text-lg text-white">No esperes a que el mundo del gaming cambie sin ti. En Legends of Interactions , puedes ser más que un jugador: puedes ser un dueño, 
        un estratega y un generador de ingresos.</p>
      <a :href="`https://api.whatsapp.com/send?phone=521${participante.telefono}&text=Hola%2C%20vi%20la%20informaci%C3%B3n%20sobre%20Legends%20of%20Interactions%F0%9F%8E%AE%20y%20quiero%20saber%20m%C3%A1s.%20%C2%BFC%C3%B3mo%20puedo%20participar%3F%E2%9C%A8`" target="_blank" @click="$emit('set-click')"
        class="mt-18 mb-12 inline-block px-9 py-3 text-white font-bold rounded-lg hover:text-white transition-all duration-300 gradient-bg hover:shadow-[0_0_15px_5px_rgba(255,255,0,0.7)]">
        💬Únete hoy y sé parte de la revolución
      </a>

      <p class="text-3xl font-serif mt-4 text-blue-900 font-bold  animated-gradient"> El momento de actuar es ahora</p>
    </section>
  </div>
</template>


<script>
export default {
  name: 'loi-master2',
  props: {
    participante: {
      type: Object,
      default() {
        return {
          nombre: "[VSC]",
          telefono: "4271615141",
          dato_identificacion: "00000000",
          web_url: "https://virtualshoppingclub.com",
          registro_url: "https://virtualshoppingclub.com/registro/1904121"
        }
      }
    },
    sinParticipantes: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.neon-text {
  text-shadow: 0 0 10px #ffffff, 0 0 10px #ffffff, 0 0 30px #ffffff;
}

.neon-black {
  text-shadow: 0 0 10px #000000, 0 0 20px #000000, 0 0 30px #000000;
}



/* Efecto Parallax */
.parallax {
  background-image: url('@/assets/img/loi-master/bannerAzul.jpg');
  height: 500px;
  background-attachment: fixed;
  /* Aquí ocurre la magia */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes gradientChange {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.gradient-bg {
  background: linear-gradient(45deg, #3a065a, #1443ef, #3a065a, #1443ef);
  background-size: 400% 400%;
  animation: gradientChange 4s ease infinite;
}


.animated-gradient {
  animation: pulse 2s infinite;
  font-size: 2rem;
  /* Ajusta el tamaño */
  font-weight: 800;
  /* Fuente extrabold */
  text-align: center;
  /* Centrar texto */
  background: linear-gradient(90deg, #fffb25, #fffb25);
  /* Gradiente de 3 colores */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* El gradiente aparecerá en lugar del color sólido */
  animation: gradient-animation 3s infinite;
  /* Añade la animación */
}

@keyframes gradient-animation {

  0%,
  100% {
    background-position: 0% 50%;
    transform: scale(0.8);
  }

  50% {
    background-position: 100% 50%;
    transform: scale(0.9);
  }
}


</style>